/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import store_1c735f1a from '/home/ergonode/pim/frontend/modules/@ergonode/ui/src/store/draggable/index.js';
import store_7d994408 from '/home/ergonode/pim/frontend/modules/@ergonode/attributes/src/store/attribute/index.js';
import store_3a3608a2 from '/home/ergonode/pim/frontend/modules/@ergonode/attribute-groups/src/store/attributeGroup/index.js';
import store_7f6dac26 from '/home/ergonode/pim/frontend/modules/@ergonode/authentication/src/store/authentication/index.js';
import store_5102f5ea from '/home/ergonode/pim/frontend/modules/@ergonode/categories/src/store/category/index.js';
import store_460663f7 from '/home/ergonode/pim/frontend/modules/@ergonode/category-trees/src/store/categoryTree/index.js';
import store_0607aca8 from '/home/ergonode/pim/frontend/modules/@ergonode/comments/src/store/comment/index.js';
import store_ab430ab0 from '/home/ergonode/pim/frontend/modules/@ergonode/conditions/src/store/condition/index.js';
import store_694eb1f0 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/unit/index.js';
import store_f5b901b2 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/tab/index.js';
import store_13342d2e from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/list/index.js';
import store_76c5ebb2 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/feedback/index.js';
import store_513fea50 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/dictionaries/index.js';
import store_0cdf0166 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/core/index.js';
import store_1c156340 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/store/alert/index.js';
import store_9c3d71b0 from '/home/ergonode/pim/frontend/modules/@ergonode/channels/src/store/channel/index.js';
import store_3ec60635 from '/home/ergonode/pim/frontend/modules/@ergonode/media/src/store/media/index.js';
import store_5b1b3b30 from '/home/ergonode/pim/frontend/modules/@ergonode/notifications/src/store/notification/index.js';
import store_196a0182 from '/home/ergonode/pim/frontend/modules/@ergonode/collections/src/store/collection/index.js';
import store_67e896a8 from '/home/ergonode/pim/frontend/modules/@ergonode/products/src/store/product/index.js';
import store_8bba0b88 from '/home/ergonode/pim/frontend/modules/@ergonode/product-statuses/src/store/productStatus/index.js';
import store_3aaa2619 from '/home/ergonode/pim/frontend/modules/@ergonode/product-templates/src/store/productTemplate/index.js';
import store_68a09528 from '/home/ergonode/pim/frontend/modules/@ergonode/segments/src/store/segment/index.js';
import store_64b49609 from '/home/ergonode/pim/frontend/modules/@ergonode/status-transitions/src/store/statusTransition/index.js';
import store_094de012 from '/home/ergonode/pim/frontend/modules/@ergonode/roles/src/store/role/index.js';
import store_0f1cf2a8 from '/home/ergonode/pim/frontend/modules/@ergonode/users/src/store/user/index.js';
import store_3445904d from '/home/ergonode/pim/frontend/modules/@ergonode/import/src/store/import/index.js';
import store_2c9f7bb5 from '/home/ergonode/pim/frontend/modules/@ergonode/workflow/src/store/workflowConditions/index.js';
import store_6ba6eecd from '/home/ergonode/pim/frontend/modules/@ergonode/workflow/src/store/workflow/index.js';

export default {
	draggable: store_1c735f1a,
	attribute: store_7d994408,
	attributeGroup: store_3a3608a2,
	authentication: store_7f6dac26,
	category: store_5102f5ea,
	categoryTree: store_460663f7,
	comment: store_0607aca8,
	condition: store_ab430ab0,
	unit: store_694eb1f0,
	tab: store_f5b901b2,
	list: store_13342d2e,
	feedback: store_76c5ebb2,
	dictionaries: store_513fea50,
	core: store_0cdf0166,
	alert: store_1c156340,
	channel: store_9c3d71b0,
	media: store_3ec60635,
	notification: store_5b1b3b30,
	collection: store_196a0182,
	product: store_67e896a8,
	productStatus: store_8bba0b88,
	productTemplate: store_3aaa2619,
	segment: store_68a09528,
	statusTransition: store_64b49609,
	role: store_094de012,
	user: store_0f1cf2a8,
	import: store_3445904d,
	workflowConditions: store_2c9f7bb5,
	workflow: store_6ba6eecd,
};
